<template>
  <div class="index_warp">
    <div class="main_top">
      <div style="display: flex">
        <el-select v-if="adminid == 1"
          v-model="procuratorateid"
          placeholder="请选择检察院名称"
          style="width: 300px;margin-right:10px"
        >
          <el-option
            v-for="item in proList"
            :key="item.id"
            :value="item.id"
            :label="item.procuratoratename"
            :disabled="disabled"
          ></el-option>
        </el-select>
        <el-select
          v-model="state"
          placeholder="请选择听证状态"
          style="width: 150px;"
        >
          <el-option
            v-for="item in options_name"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          >
          </el-option>
        </el-select>

        <el-select
          v-model="hringtype"
          placeholder="请选择主持人职务"
          style="width: 170px;"
        >
          <el-option
            v-for="item in hringList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>

        <div class="block">
          <el-date-picker
            v-model="value_time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 300px;"
          >
          </el-date-picker>
        </div>
        <el-input
          clearable
          v-model="selectTxt"
          placeholder="请输入听证项目名称、主持人姓名搜索"
          style="width: 300px; margin-left: 10px"
          class="select-actName"
        ></el-input>

        <el-button
          slot="reference"
          class="style_button style_button_Sel"
          icon="el-icon-search"
          @click="activitySelect"
          >搜索</el-button
        >
      </div>
      <el-button
        class="style_button"
        type="text"
        @click="handleExport()"
        >导出</el-button
      >
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      row-key="id"
      :tree-props="{ children: 'record', hasChildren: 'hasChildren' }"
    >
      <el-table-column label="序号" width="100" type="index"> </el-table-column>
            <el-table-column label="检察院名称" width="160">
        <template slot-scope="scope">
          <p>
            {{ scope.row.procuratoratename }}
          </p>
        </template>
      </el-table-column>
      <el-table-column label="听证项目" class="act_name">
        <template slot-scope="scope">
          <p>
            {{ scope.row.hringname }}
          </p>
        </template>
      </el-table-column>
      <el-table-column label="听证时间" width="140">
        <template slot-scope="scope">
          <p>
            {{ scope.row.hringdate }}
          </p>
        </template>
      </el-table-column>

      <el-table-column label="主持人" width="140">
        <template slot-scope="scope">
          <p>
            {{ scope.row.comperename }}
          </p>
        </template>
      </el-table-column>

      <el-table-column label="主持人职务" width="140">
        <template slot-scope="scope">
          <p>
            {{ scope.row.comperetypename }}
          </p>
        </template>
      </el-table-column>
      <el-table-column label="听证状态" width="140">
        <template slot-scope="scope">
          <p v-if="scope.row.state == 0">待审核</p>
          <p v-if="scope.row.state == 1">已审核</p>
          <p v-if="scope.row.state == 2">待确认</p>
          <p v-if="scope.row.state == 3">已确认</p>
          <p v-if="scope.row.state == 4">已完成</p>
          <p v-if="scope.row.state == 5">已驳回</p>
          <p v-if="scope.row.state == 6">已取消</p>
        </template>
      </el-table-column>
      <el-table-column label="操作" class="operate" width="120">
        <template slot-scope="scope">
          <el-button size="mini" @click="handledetail(scope.row.id)"
            >查看详情</el-button
          >
          <!-- <el-button
            size="mini"
            @click="handleExportId(scope.row.id)"
            style="color: #3a8fea; background: white; border: 1px solid #3a8fea"
            >导出详情</el-button
          > -->
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :visible.sync="dialogFormVisible"
      @close="closeDialog"
      :close-on-click-modal="false"
    >
      <div slot="title" class="dialog_title">
        <span>听证详情</span>
      </div>
      <div class="hearContent">
        <p class="hearTitle">听证项目详情</p>
        <el-table border :data="hearInfo" style="width: 100%">
          <el-table-column prop="hringname" label="项目名称"> </el-table-column>
          <el-table-column prop="hringdate" label="听证时间">
          </el-table-column>
          <el-table-column prop="hringstarttime" label="开始时间" >
          </el-table-column>
          <el-table-column prop="hringendtime" label="结束时间" >
          </el-table-column>
          <el-table-column prop="ispublic" label="是否公开" >
            <template slot-scope="scope">
              <p>{{ scope.row.ispublic == 0 ? "不公开" : "公开" }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="islive" label="是否需要互联网直播">
            <template slot-scope="scope">
              <p>{{ scope.row.islive == 0 ? "不需要" : "需要" }}</p>
            </template>
          </el-table-column>
        </el-table>
        <el-table border :data="hearInfo" style="width: 100%">
          <el-table-column prop="remark" label="备注">
          </el-table-column>

          <el-table-column
            prop="procuratoratename"
            label="检察院信息"
          >
            <template slot-scope="scope">
              <p>{{ scope.row.procuratoratename }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="depname" label="部门信息" >
            <template slot-scope="scope">
              <p>{{ scope.row.depname }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="phone" label="手机号"> </el-table-column>
          <el-table-column prop="casetype" label="案件类型"> </el-table-column>
          <el-table-column prop="casename" label="案件名称"> </el-table-column>
        </el-table>

        <el-table border :data="hearInfo" style="width: 100%">
          <el-table-column prop="professionalList" label="专业领域">
          </el-table-column>

          <el-table-column prop="comperename" label="主持人">
          </el-table-column>
          <el-table-column
            prop="comperetypename"
            label="主持人职务"
          
          >
          </el-table-column>
          <el-table-column label="听证状态">
            <template slot-scope="scope">
              <p v-if="scope.row.state == 0">待审核</p>
              <p v-if="scope.row.state == 1">已审核</p>
              <p v-if="scope.row.state == 2">待确认</p>
              <p v-if="scope.row.state == 3">已确认</p>
              <p v-if="scope.row.state == 4">已完成</p>
              <p v-if="scope.row.state == 5">已驳回</p>
              <p v-if="scope.row.state == 6">已取消</p>
            </template>
          </el-table-column>
          <el-table-column prop="username" label="检察官名称">
          </el-table-column>
          <el-table-column prop="count" label="参加听证人数">
          </el-table-column>
        </el-table>
      </div>
      <div class="hearContent">
        <p class="hearTitle">参与听证专家</p>

        <el-table border :data="expertList" style="width: 100%" height="300">
          <el-table-column prop="username" label="听证员姓名" width="180">
          </el-table-column>
          <el-table-column prop="sex" label="性别" width="180">
            <template slot-scope="scope">
              <p>{{ scope.row.sex == 0 ? "女" : "男" }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="phone" label="电话"> </el-table-column>
          <el-table-column prop="professional" label="专业领域">
          </el-table-column>
          <el-table-column prop="procuratoratename" label="所属检察院">
          </el-table-column>
          <el-table-column prop="start" label="是否参加">
            <template slot-scope="scope">
              <p v-if="scope.row.start == 0">待确认</p>
              <p v-if="scope.row.start == 1">确认参加</p>
              <p v-if="scope.row.start == 2">确认不参加</p>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <div class="block">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
        hide-on-single-page
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { Message } from "element-ui";
import { downloadFile } from "@/util/formtime";
import { BASE_URL } from "@/config";
import {
  getAllHearing,
  getHearingExpertInfo,
  getHearingInfoById,
  hringtype,
} from "@/api/hearing";
//  fuzzySearchHearing,
import { getProInfoList } from "@/api/system";
export default {
  data() {
    return {
      adminid:JSON.parse(window.localStorage.getItem("tltz_userinfo")).id,
      value: [],
      perList: [],
      tableData: [], //活动列表
      total: 0, //总条数
      page: 1, //页数
      pageSize: 10, //每页限制
      currentPage: 1, //当前页数
      state: -1, //当前状态
      options_name: [
        {
          id: -1,
          label: "全部",
        },
        {
          id: 0,
          label: "待审核",
        },
        {
          id: 1,
          label: "已审核",
        },
        {
          id: 2,
          label: "待确认",
        },
        {
          id: 3,
          label: "已确认",
        },
        {
          id: 4,
          label: "已完成",
        },
        {
          id: 5,
          label: "已驳回",
        },
        {
          id: 6,
          label: "已取消",
        },
      ],
      selectTxt: "", //搜索当前项
      procuratorateid: null,
      proList: [],
      value_time: null, //搜索时间
      hearInfo: [{}], //听证详情
      expertList: [], //专家列表
      dialogFormVisible: false,
      formLabelWidth: "75px",
      disabled: false,
      hringtype: -1,
      hringList: [],
    };
  },
  mounted() {
    this._hringtype();
    this.getproList();
    this.typeid = JSON.parse(
      window.localStorage.getItem("tltz_userinfo")
    ).types.id;
    if (this.typeid == 6) {
      this.procuratorateid = -1;
    } else {
      this.procuratorateid = JSON.parse(
        window.localStorage.getItem("tltz_userinfo")
      ).procuratorate.id;
      this.disabled = true;
    }
    this._getAllHearing();
    this.perList = JSON.parse(window.localStorage.getItem("permissionidList"));
    console.log(this.perList);
  },
  methods: {
    _hringtype() {
      hringtype().then((data) => {
        if (data.code == 200) {
          this.hringList = data.data;
          this.hringList.unshift({
            id: -1,
            name: "全部",
          });
        }
      });
    },
    dateFormat(dateData) {
      var date = new Date(dateData);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      const time = y + "-" + m + "-" + d;
      return time;
    },
    //获取列表
    getproList() {
      getProInfoList(this.page, this.pageSize).then((data) => {
        this.proList = data.data.list;
        this.proList.unshift({
          id: -1,
          procuratoratename: "全部",
        });
      });
    },
    //获取活动列表
    _getAllHearing() {
      const info = {
        adminid: JSON.parse(window.localStorage.getItem("tltz_userinfo")).id,
        procuratorateid: this.procuratorateid,
        state: this.state,
        conditions: this.selectTxt,
        hringtype: this.hringtype,
      };
      console.log(this.value_time);
      if (this.value_time !== null) {
        // info.stdate = this.value_time[0]
        // info.enddate =this.value_time[1]
        info.stdate = this.dateFormat(this.value_time[0]);
        info.enddate = this.dateFormat(this.value_time[1]);
      } else {
        info.stdate = "";
        info.enddate = "";
      }
      getAllHearing(info, this.page, this.pageSize).then((data) => {
        // console.log(data);
        if (data.data == null) {
          this.page = 1;
          this.tableData = [];
          this.total = 0;
          this.currentPage = 1;
          // this. _getAllHearing();
          return;
        } else {
          this.tableData = data.data.list;
          this.total = data.data.total;
          this.currentPage = data.data.pageNum;
          this.states = this.tableData;
        }
      });
    },
    //查看详情
    handledetail(id) {
      let res = this.perList.filter((val) => val == 10);
      console.log(res);
      if (!res.length) {
        Message.error("您没有这个权限");
      } else {
        this.expertList = []
        getHearingInfoById(id).then((data) => {
          console.log(data, "datadatadata");
          data.data.depname = data.data.department.depname;
          data.data.procuratoratename =
            data.data.procuratorate.procuratoratename;
          if (data.data.professionallist.length == 0) {
            data.data.professionalList = "无要求";
          } else {
            let str = "";
            data.data.professionallist.forEach((item) => {
              str += item.professional + "*" + item.count + ";";
            });
            str = str.slice(0, str.length - 1);
            console.log(str);
            data.data.professionalList = str;
          }
          let Obj = data.data;
          for (const i in Obj) {
            this.$set(this.hearInfo[0], i, Obj[i]);
          }

          console.log(this.hearInfo, "进来info");
        });
        getHearingExpertInfo(id, 1, 999).then((data) => {
          if (data.data !== undefined) {
            this.expertList = data.data.list;
          }
        });
        this.dialogFormVisible = true;
      }
    },
    //搜索
    activitySelect() {
      this._getAllHearing();
    },
    closeDialog() {
      this.dialogFormVisible = false;
    },
    //导出
    handleExport() {
      let res = this.perList.filter((val) => val == 104);
      console.log(res);
      if (!res.length) {
        Message.error("您没有这个权限");
      } else {
        var adminid= JSON.parse(window.localStorage.getItem("tltz_userinfo")).id
        var procuratorateid= this.procuratorateid
        var state= this.state
        var conditions= this.selectTxt
        var hringtype= this.hringtype
        downloadFile(BASE_URL + `/hring/Exporthring?adminid=${adminid}&procuratorateid=${procuratorateid}&state=${state}&conditions=${conditions}&hringtype=${hringtype}`);
      }
    },
    //导出单个详情
    handleExportId(id) {
      console.log(id);
      let res = this.perList.filter((val) => val == 73);
      console.log(res);
      if (!res.length) {
        Message.error("您没有这个权限");
      } else {
        downloadFile(BASE_URL + `/Hearing/exportOneHearing?id=${id}`);
      }
    },
    //分页器
    handleCurrentChange(val) {
      this.page = val;
      console.log(this.selectTxt);
      if (this.selectTxt !== "") {
        this.activitySelect();
        return;
      }
      this._getAllHearing();
    },
  },
};
</script>
<style lang='less' >
.index_warp {
  box-sizing: border-box;
  position: relative;
  width: auto;
  height: 92%;
  background: #fff;
  margin-top: 16px;
  padding: 20px 13px 54px 13px;
  .main_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .select-actName {
      margin-right: 10px;
    }
    .el-select {
      margin-right: 10px;
    }
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 400px;
    }
  }
  .style_button {
    width: 100px;
    height: 40px;
    background: #044bfe;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 300;
    color: #ffffff;
    margin-left: 10px;
  }
  .hearContent {
    margin-bottom: 30px;
    .hearTitle {
      font-size: 16px;
    }
  }
  .el-dialog {
    // width: 70%;
    min-width: 1450px;
    .el-dialog__body {
      .el-form {
        .block {
          height: 20px;
          .el-date-editor.el-input,
          .el-date-editor.el-input__inner {
            width: 600px !important;
          }
        }
      }
    }
    .dialog_title {
      > span {
        font-size: 13px;
        font-weight: 400;
        color: #2d8cf0;
      }
    }
  }

  .el-table td,
  .el-table th {
    text-align: center;
    padding: 8px 0;
  }
  .el-table {
    margin-top: 15px;
    .el-button {
      width: 90px !important;
      height: 36px;
      background: #3a8fea;
      font-size: 12px !important;
      color: #ffffff;
      border-radius: 10px;
    }
  }
  .el-pagination {
    text-align: center;
    margin-top: 15px;
  }
  .el-pager {
    > li {
      border: 1px solid #d2dae2;
      border-radius: 2px 2px 2px 2px;
      width: 28px !important;
      height: 28px !important;
      font-size: 12px;
      margin-right: 5px;
      min-width: 28px;
    }
    > li:last-child {
      margin-right: 0;
    }
    > li.active {
      box-sizing: border-box;
      background: #3a8fea;
      color: #fff;
      width: 28px !important;
      height: 28px !important;
    }
    li.active + li {
      border-left: 1px solid #d2dae2;
    }
  }
  .cell {
    display: inline-block;
    padding: 0;
    text-align: center;
  }
}
.el-button--danger {
  border-color: transparent;
}
.el-pagination .btn-prev,
.el-pagination .btn-next {
  border: 1px solid #d2dae2;
  border-radius: 2px 2px 2px 2px;
  width: 28px !important;
  height: 28px !important;
  font-size: 12px;
  margin: 0 5px;
  min-width: 28px;
}
</style>
